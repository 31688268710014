<template>
    <v-layout column>
         <v-layout class="big-toolbar tertiary-background" justify-start pt-10 align-center column>
            <span class="header"><strong>RASA</strong> - NATURAL LANGUAGE PROCESSOR</span>
            <v-card class="content secondary-background">
                <v-layout column align-center>
                    <span class="title-error">
                        Ooops, Ocorreu um erro!
                    </span>

                    <span class="msg-error">{{error}}</span>

                    <v-layout mt-4 justify-center>
                        <v-btn color="teal" text :to="{path: '/home'}">
                            Página principal
                        </v-btn>
                    </v-layout>
                </v-layout>
            </v-card>
        </v-layout>
    </v-layout>
</template>

<script>
export default {
    data() {
        return {
            error: this.$store.getters.getErrorMessage
        }
    },
}
</script>

<style scoped lang="scss">
    .big-toolbar{
        z-index: 3;
        background: linear-gradient(to right, #eef2f3, #8e9eab);

        .header {
            font-size: 24px;
            padding: 10px;
            font-weight: 300;

            strong {
                font-weight: 1000;
            }
        }

    }
    .content {
        display: flex;
        padding: 20px;
        width: 50%;
        min-height: 200px;
               

        .title-error {
            font-size: 22px !important;
            font-weight: 500;
            margin: 0 auto;
        }

        .msg-error {
            font-size: 18px;
            margin: 10px auto;
        }

    }

    .highligth {
        color: #12c19c;
    }
</style>
